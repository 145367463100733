export enum ACCOUNT_STATE {
  LOGIN,
  REGISTE,
  FORGET_PWD,
  QR_CODE,
}

export enum LOGIN_STYLE {
  ACCOUNT, // 账号登录
  CODE, // 二维码登录
}

export enum AGREEMENT_TYPE {
  SERVICE_PROTOCOL, // 服务协议
  PRIVACY_PROTOCOL, // 隐私协议
}

export enum SMS_TYPE {
  REGISTE = 11,
  RESET_PASSWORD = 12,
}

export enum BOX_VERSION {
  BASIC = 'basic',
  ADVANCE = 'advance',
  PRIVACY = 'privacy'
}
