<template>
  <div class="wrapper">
    <el-dialog
      :visible="visible"
      width="1080px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="container">
        <div class="left">
          <img :src="accountImg" alt="" />
        </div>
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close" />
          </div>
          <Login
            v-if="state === ACCOUNT_STATE.LOGIN"
            @onAccountStateChange="handleAccountStateChange"
          />
          <Registe
            v-else-if="state === ACCOUNT_STATE.REGISTE"
            @back="handleAccountStateChange"
          />
          <Forget
            v-else-if="state === ACCOUNT_STATE.FORGET_PWD"
            @back="handleAccountStateChange"
          />
          <QrCode
            v-else-if="state === ACCOUNT_STATE.QR_CODE"
            @back="handleAccountStateChange"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { ACCOUNT_STATE } from "@/utils/enums";
import accountImg from "@/assets/images/account/account-img.png";

@Component({
  components: {
    Login: () => import("./login.vue"),
    Registe: () => import("./registe.vue"),
    Forget: () => import("./forget.vue"),
    QrCode: () => import("./qrcode.vue"),
  },
})
export default class extends Vue {
  visible = false;
  accountImg = accountImg;
  ACCOUNT_STATE = ACCOUNT_STATE;
  state = ACCOUNT_STATE.LOGIN;

  handleAccountStateChange(s: ACCOUNT_STATE) {
    if (s !== this.state) this.state = s;
  }

  open(state: ACCOUNT_STATE) {
    this.state = state;
    this.visible = true;
  }

  close() {
    this.visible = false;
  }

  mounted() {
    this.state = ACCOUNT_STATE.LOGIN;
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  :deep(.el-dialog) {
    background: unset;
  }

  :deep(.el-dialog__header) {
    display: none;
  }

  :deep(.el-dialog__body) {
    height: 570px;
    padding: 0;
  }

  :deep(.el-icon-close) {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }
}

.container {
  display: flex;

  .left {
    width: 670px;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    position: relative;
    flex: 1;
    background-image: url(@/assets/images/account/account_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      :deep(.el-icon-close) {
        position: static;
        font-size: 20px;
        color: #e4c08c;
      }
    }

    :deep(.el-input) {
      input {
        height: 46px;
        color: #f5d587;
        background-color: unset;
        border: 1px solid #e4c08c;

        &::placeholder {
          color: #f5d58780;
        }
      }

      .el-input__prefix {
        color: #f5d587;
      }

      .el-input-group__append {
        background-color: #e4c08c;
        color: #522008;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.28px;
        border: unset;
      }
    }
  }
}
</style>
